/* eslint-disable react-hooks/rules-of-hooks */

import {
  useRunbookType,
  useRunbookTypeCallback,
  useRunbookTypeCallbackSync,
  useRunbookTypeFromRunbook,
  useRunbookTypeLookup,
  useRunbookTypeLookupCallback,
  useRunbookTypeLookupCallbackSync,
  useRunbookTypes,
  useRunbookTypesCallback,
  useRunbookTypesCallbackSync
} from '../hooks/recoil/runbook-type'
import { RunbookTypeModelType } from './types'

export const RunbookTypeModel: RunbookTypeModelType = {
  get: useRunbookType,
  getFromRunbook: useRunbookTypeFromRunbook,
  getCallback: useRunbookTypeCallback,
  getCallbackSync: useRunbookTypeCallbackSync,
  getAll: useRunbookTypes,
  getAllCallback: useRunbookTypesCallback,
  getAllCallbackSync: useRunbookTypesCallbackSync,
  getLookup: useRunbookTypeLookup,
  getLookupCallback: useRunbookTypeLookupCallback,
  getLookupCallbackSync: useRunbookTypeLookupCallbackSync
}
